<template>
	<div
		id="vuesoma"
		:data-theme="verticalTheme"
	>
		<img
			class="printable-logo media-screen-hide"
			src="@local-assets/img/logo-horizontal-alt.svg"
			alt=""
			aria-hidden="true"
		/>
		<span
			class="printable-margin-text media-screen-hide"
			aria-hidden="true"
		>
			{{ legalIdentity }}
		</span>
		<router-view :inert="inert" />
		<w-notification />
		<w-modal />
		<router-view name="modal" />
		<c-fullscreen-spinner
			v-if="status"
			:type="type"
			data-testid="spinner"
		/>
		<component :is="verticalThemeComponent" />
	</div>
</template>

<script>
import { throttle } from 'lodash';
import { mapState } from 'vuex';
import CFullscreenSpinner from '@components/c-fullscreen-spinner';
import WModal from '@widgets/w-modal';
import WNotification from '@widgets/w-notification';
import MConfirm from '@modals/m-confirm';
import MNoInternet from '@modals/m-no-internet';
import { importLocale } from '@locales/setup';

const ONE_SECOND = 1000;
const TEN_SECONDS = 10000;
const MAX_MINUTES_FOR_SESSION_EXPIRATION = 5;
const MAX_MINUTES_FOR_SESSION_EXPIRATION_ALERT = 4.5;
let ONCE = true;

const isPrd = window.VUE_APP_CONFIG?.env === 'prd';

export default {
	name: 'app',

	components: {
		CFullscreenSpinner,
		WNotification,
		WModal,
	},

	data() {
		return {
			expiredSessionTimeOut: 0,
			alertSessionTimeout: 0,
			expiredSessionMaxMinutes: MAX_MINUTES_FOR_SESSION_EXPIRATION,
			alertSessionMaxMinutes: MAX_MINUTES_FOR_SESSION_EXPIRATION_ALERT,
			verticalTheme: 'cbnk-light',
		};
	},

	computed: {
		...mapState('authn', ['isLoggedIn']),
		...mapState('session', ['theme', 'lang', 'userId', 'vertical']),
		...mapState('app', [
			'legalIdentity',
			'isHybridSky',
			'isHybrid',
			'isEmbedded',
		]),
		...mapState('loading', ['status', 'type']),
		...mapState('service', ['lastRequestTimestamp', 'offline']),
		...mapState('device', ['isPWA']),
		...mapState('secure', ['uuid']),

		inert({
			$store: {
				getters: {
					'modal/lastOpened': lastModalOpened = false,
					'dialog/lastOpened': lastDialogOpened = false,
				},
			},
		}) {
			return Boolean(lastModalOpened || lastDialogOpened);
		},

		verticalThemeComponent({ vertical }) {
			const verticalType = {
				1: 'eng',
				2: 'sal',
			}[vertical];

			if (verticalType) {
				return () =>
					import(`@cbnk/vertical/${verticalType}/vertical-theme.vue`);
			}

			return '';
		},
	},

	watch: {
		/* istanbul ignore next */
		isPWA: {
			handler(value) {
				if (value) {
					document.addEventListener(
						'contextmenu',
						(e) => {
							/* istanbul ignore next */
							if (e.target.tagName.toLowerCase() !== 'input') {
								e.preventDefault();
							}
						},
						false
					);
				}
			},
		},

		/* istanbul ignore next */
		isLoggedIn: {
			handler(value) {
				var cookieBotWidget = document.getElementById('CookiebotWidget');
				if (!value) {
					if (cookieBotWidget) {
						cookieBotWidget.style.display = 'inline';
					}
					clearInterval(this.expiredSessionTimeOut);
					clearInterval(this.alertSessionTimeout);

					if (this.$route.name !== 'login') {
						this.$router.push({ name: 'login' });
					}
				} else {
					if (cookieBotWidget) {
						cookieBotWidget.style.display = 'none';
					}
					this.expiredSessionTimeOut = setInterval(
						this.checkForSessionExpiration,
						ONE_SECOND
					);
					this.alertSessionTimeout = setInterval(
						this.alertForSessionAboutToExpire,
						ONE_SECOND
					);
				}
			},
		},

		theme: {
			immediate: true,
			handler(value) {
				document.documentElement.dataset.theme = value;
				this.setVerticalTheme(value);
			},
		},

		/* istanbul ignore next */
		lang: {
			handler(value) {
				importLocale(value, this.vertical);
			},
		},

		/* istanbul ignore next */
		uuid: {
			handler(next, prev) {
				// This is needed for assisted channels,
				// when the session is falsy we have to notify assisted channels
				if (prev && !next && window.parent) {
					window.parent.postMessage(
						{
							name: 'error',
							userId: this.userId,
							sessionUUID: prev,
							error: 'session-expired',
						},
						'*'
					);
				}
			},
		},

		offline: {
			async handler(value) {
				if (value) {
					await this.$store.dispatch('modal/open', MNoInternet);
					this.$store.dispatch('service/setOffline', false);

					if (this.isHybridSky || this.isHybrid) {
						this.$store.dispatch('authn/logout');
					}
				}
			},
		},

		vertical: {
			immediate: true,
			handler() {
				this.setVerticalTheme(this.theme);
			},
		},
	},

	methods: {
		setVerticalTheme(theme) {
			const verticalType = {
				0: 'cbnk',
				1: 'eng',
				2: 'sal',
			}[this.vertical];
			this.verticalTheme = `${verticalType}-${theme}`;

			return importLocale(this.lang, this.vertical);
		},

		async alertForSessionAboutToExpire() {
			const {
				$store: { dispatch },
				lastRequestTimestamp,
				alertSessionMaxMinutes,
			} = this;
			const minutesFromLastRequest =
				(new Date(Date.now()).getTime() - lastRequestTimestamp) / 1000 / 60;

			/* istanbul ignore else */
			if (minutesFromLastRequest > alertSessionMaxMinutes) {
				const props = {
					title: this.$t('INFO.SESSION_ABOUT_EXPIRE.TITLE'),
					text: this.$t('INFO.SESSION_ABOUT_EXPIRE.DESC'),
					acceptText: this.$t('ACTIONS.CONTINUE_SESSION'),
					cancelText: this.$t('ACTIONS.CLOSE_SESSION'),
					modal: true,
				};

				clearInterval(this.alertSessionTimeout);
				const confirmation = await dispatch('modal/open', {
					component: MConfirm,
					props,
				});

				// Refresh session if user clicks accept or closes the modal
				// TODO: When we close a modal, should emit an event such as 'modal-closed'
				if (confirmation || confirmation === null) {
					this.alertSessionTimeout = setInterval(
						this.alertForSessionAboutToExpire,
						ONE_SECOND
					);

					const updateRSISession = this.$route.name !== 'sso-rsi';

					return dispatch('authn/refresh', {
						skipLogout: false,
						updateRSISession,
					});
				}

				dispatch('authn/logout');
			}
		},

		checkForSessionExpiration() {
			const { lastRequestTimestamp, expiredSessionMaxMinutes } = this;
			const minutesFromLastRequest =
				(new Date(Date.now()).getTime() - lastRequestTimestamp) / 1000 / 60;

			/* istanbul ignore else */
			if (minutesFromLastRequest > expiredSessionMaxMinutes) {
				clearInterval(this.expiredSessionTimeOut);

				return this.$store.dispatch('authn/passiveLogout');
			}
		},

		handleRefresh() {
			if (this.isLoggedIn) {
				this.$store.dispatch('authn/refresh', {
					skipLogout: false,
					updateRSISession: false,
				});
			}
		},
	},

	created() {
		/* istanbul ignore next */
		this.$router.afterEach((to, from) => {
			this.$store.dispatch('bugsnag/log', {
				type: 'navigation',
				title: 'Router push',
				from: from.path,
				to: to.path,
			});
		});

		window.dispatchEvent(new Event('app-about-to'));

		/* istanbul ignore next */
		if (!isPrd && !this.isEmbedded) {
			const { log } = console;

			[
				'native-hide-loading',
				'native-copy-text',
				'native-open-external-link',
				'native-save-file',
				'native-share-file',
				'native-exit-app',
				'native-open-whatsapp',
				'native-open-email',
				'native-open-telephone',
				'native-set-theme',
				'native-get-wallet-status',
				'native-get-wallet-state',
				'native-get-wallet-data',
				'native-add-card-to-wallet',
				'native-start-card-provisioning',
				'splashscreen-unload',
			].forEach((eventName) => {
				window.addEventListener(
					eventName,
					(event) => {
						log({
							type: event?.type,
							detail: { ...event?.detail },
						});
					},
					false
				);
			});

			window.addEventListener(
				'message',
				(event) => {
					if (event?.data?.name) {
						log({
							name: event.data.name,
							data: event?.data,
						});
					}
				},
				false
			);
		}
		this.handleThrottleRefresh = throttle(this.handleRefresh, TEN_SECONDS);
		window.addEventListener('click', this.handleThrottleRefresh, true);
		window.addEventListener('scroll', this.handleThrottleRefresh, true);
		window.addEventListener('keydown', this.handleThrottleRefresh, true);
	},

	updated() {
		this.$nextTick(() => {
			// When skyline is embedded sometimes the frame is mounted
			// but it is not visible so the splash transition can't be listened
			// to fix it we remove the splash screen after the first update
			if (ONCE && this.isEmbedded && document.getElementById('splash')) {
				document.getElementById('splash')?.remove();
				ONCE = false;
			}
		});
	},

	mounted() {
		// We guarantee that all child components have been mounted
		// and the entire view has been rendered
		this.$nextTick(() => {
			window.dispatchEvent(new Event('app-is-mounted'));
		});
	},
};
</script>

<style lang="scss" scoped>
#vuesoma {
	height: 100%;
	display: flex;
}

[inert] {
	overflow: hidden;
}
</style>

<style lang="scss">
@import '~@design/index.scss';

body {
	@extend %typo-m-book;
}

.v__internal-a11y-hide {
	position: absolute;
	clip: rect(1px, 1px, 1px, 1px);
	background: 0 0;
	color: transparent;
	width: 1px;
	height: 1px;
	overflow: hidden;
}

:not([tabindex='-1']):focus:focus-visible::before {
	content: '';
	display: block;
	position: absolute;
	border-radius: var(--focus-ring-radius, inherit);
	top: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	bottom: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	left: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	right: calc(-1 * var(--focus-ring-padding, 0px) - 1px);
	border: 1px solid RGB(var(--color-secondary));
	box-shadow: 0 0 0 1px RGB(var(--color-secondary));
}

@media (pointer: fine), (hover) {
	::-webkit-scrollbar {
		width: 16px;
	}

	::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.2),
			inset 0 0 0 30px rgba(0, 0, 0, 0.3);
		border: 4px solid transparent;
	}

	::-webkit-scrollbar-thumb:window-inactive {
		visibility: hidden;
	}
}

.media-screen-hide {
	display: none !important;
}

@media print {
	body {
		--color-gradient-header: none;
		background: white !important;
	}

	.media-screen-hide {
		display: block !important;
	}

	.media-print-hide {
		display: none !important;
	}

	.printable-margin-text {
		position: fixed;
		z-index: var(--z-index-base) 0;
		left: 0;
		top: 0;
		bottom: 0;
		text-align: center;
		writing-mode: vertical-rl;
		transform: rotate(-180deg);
		color: RGB(var(--color-text-primary));
		opacity: 0.6;
	}

	.printable-logo {
		position: fixed;
		top: 0;
		left: 0;
		z-index: var(--z-index-over-page);
		height: 32px;
	}
}
</style>
